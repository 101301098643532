import { VDialog } from 'vuetify/lib/components/VDialog';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "min-height": "100vh" }, attrs: { id: "rounds-bg" } },
    [
      _vm.loading
        ? _c(
            "div",
            { staticClass: "round-loading" },
            [
              _c(VProgressCircular, {
                attrs: { indeterminate: "", size: "70", color: "primary" },
              }),
            ],
            1
          )
        : _c("div", { attrs: { id: "rounds-content" } }, [
            _c("div", { staticClass: "round-details-header" }, [
              _c(
                "a",
                {
                  staticClass: "round-details-header-back-page",
                  on: {
                    click: function () {
                      return _vm.handleBack()
                    },
                  },
                },
                [
                  _c("font-awesome-icon", {
                    staticClass: "round-details-header-back-page-icon",
                    attrs: { icon: "fa-solid fa-arrow-left" },
                  }),
                  _c(
                    "span",
                    { staticClass: "round-details-header-back-page-text" },
                    [_vm._v(_vm._s(_vm.$t("rounds_title")))]
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "round-details-header-flex" }, [
                _c("div", [
                  _c("h1", { staticClass: "round-details-header-title" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("single_round") + " #" + _vm.roundData.id
                        ) +
                        " "
                    ),
                  ]),
                  _c("p", { staticClass: "round-details-header-sub-title" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("information_about_round_sub_title")) +
                        " "
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "round-details-header-buttons" }, [
                  _c(
                    "div",
                    [
                      _c("NewDxaButton", {
                        attrs: {
                          outline: true,
                          title: _vm.$t("cancel_round"),
                          disabled:
                            _vm.buttonCancelRoundDisabled || _vm.isManagerUser,
                        },
                        on: {
                          btnFunction: function ($event) {
                            _vm.cancelRoundDialog = true
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("NewDxaButton", {
                        attrs: {
                          title: _vm.$t(_vm.closeOrOpenTextButton),
                          disabled:
                            _vm.closeOrOpenRoundButtonDisabled ||
                            _vm.isManagerUser,
                        },
                        on: {
                          btnFunction: function ($event) {
                            _vm.closeOrOpenRoundDialog = true
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "round-allocations-card" }, [
              _c("div", [
                _c("div", { staticClass: "round-details-allocations-header" }, [
                  _c(
                    "div",
                    { staticClass: "rounds-details-allocations-company-logo" },
                    [
                      _c("img", {
                        attrs: {
                          src: _vm.gs.get_photo_path(_vm.roundData.companyLogo),
                          alt: "Company Logo",
                        },
                      }),
                    ]
                  ),
                  _c("div", [
                    _c(
                      "h3",
                      {
                        staticClass:
                          "round-details-allocations-header-round-id",
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("single_round") + " #" + _vm.roundData.id
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _c(
                      "h2",
                      {
                        staticClass:
                          "round-details-allocations-header-company-name",
                      },
                      [_vm._v(" " + _vm._s(_vm.roundData.companyName) + " ")]
                    ),
                    _c(
                      "p",
                      {
                        staticClass: "round-details-allocations-header-status",
                        style: "color:" + _vm.selectOpportunityTextColor,
                      },
                      [
                        _c("font-awesome-icon", {
                          staticClass:
                            "round-details-allocations-header-circle-icon",
                          attrs: {
                            color: _vm.statusCircleIconColor,
                            icon: "fa-solid fa-circle",
                          },
                        }),
                        _vm._v(
                          " " + _vm._s(_vm.$t(_vm.selectOpportunityText)) + " "
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "round-details-allocations-header-details" },
                  [
                    _c("div", [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.roundAllocationData.totalAllocations +
                              " " +
                              _vm.$t("allocations")
                          )
                        ),
                      ]),
                      _c(
                        "a",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.seeRoundAllocations(_vm.roundData.id)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("see_allocations")))]
                      ),
                    ]),
                    _c("div", { staticClass: "no-gap" }, [
                      _c("span", { staticClass: "space" }, [_vm._v("|")]),
                      _c("span", { staticClass: "total-accesses-label" }, [
                        _vm._v(
                          _vm._s(
                            (_vm.roundData.totalAccesses
                              ? _vm.roundData.totalAccesses
                              : 0) +
                              " " +
                              _vm.$t("accesses")
                          )
                        ),
                      ]),
                      !_vm.isManagerUser
                        ? _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.seeRoundAccesses(_vm.roundData.id)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("see_accesses")))]
                          )
                        : _vm._e(),
                    ]),
                  ]
                ),
                _vm.roundAllocationData
                  ? _c(
                      "div",
                      { staticClass: "round-allocations-card-info-container" },
                      [
                        _c(
                          "div",
                          { staticClass: "round-allocations-card-info" },
                          [
                            _c(
                              "span",
                              { staticClass: "round-allocations-card-title" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("total_allocations")) +
                                    " "
                                ),
                              ]
                            ),
                            _vm._l(
                              _vm.roundAllocationData.totalInvested,
                              function (invested, i) {
                                return _c(
                                  "span",
                                  {
                                    key: i,
                                    staticClass: "round-allocations-card-value",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        invested.value
                                          ? _vm.formatCurrency(
                                              invested.currency,
                                              invested.value
                                            )
                                          : "-"
                                      )
                                    ),
                                  ]
                                )
                              }
                            ),
                          ],
                          2
                        ),
                        _c(
                          "div",
                          { staticClass: "round-allocations-card-info" },
                          [
                            _c(
                              "span",
                              { staticClass: "round-allocations-card-title" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("ongoing_allocations")) +
                                    " "
                                ),
                              ]
                            ),
                            _vm._l(
                              _vm.roundAllocationData.totalRequestPending,
                              function (pending, i) {
                                return _c(
                                  "span",
                                  {
                                    key: i,
                                    staticClass: "round-allocations-card-value",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        pending.value
                                          ? _vm.formatCurrency(
                                              pending.currency,
                                              pending.value
                                            )
                                          : "-"
                                      )
                                    ),
                                  ]
                                )
                              }
                            ),
                          ],
                          2
                        ),
                        _c(
                          "div",
                          { staticClass: "round-allocations-card-info" },
                          [
                            _c(
                              "span",
                              { staticClass: "round-allocations-card-title" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("confirmed_transfers")) +
                                    " "
                                ),
                              ]
                            ),
                            _vm._l(
                              _vm.roundAllocationData.totalRequestConfirmed,
                              function (confirmed, i) {
                                return _c(
                                  "span",
                                  {
                                    key: i,
                                    staticClass: "round-allocations-card-value",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        confirmed.value
                                          ? _vm.formatCurrency(
                                              confirmed.currency,
                                              confirmed.value
                                            )
                                          : "-"
                                      )
                                    ),
                                  ]
                                )
                              }
                            ),
                          ],
                          2
                        ),
                      ]
                    )
                  : _vm._e(),
                _c("div", { staticClass: "round-allocations-sectors" }, [
                  _c("span", { staticClass: "round-allocations-sector-name" }, [
                    _vm._v(
                      " " + _vm._s(_vm.roundAllocationData.company.sector) + " "
                    ),
                  ]),
                ]),
                _c("span", { staticClass: "round-allocations-last-update" }, [
                  _vm._v(
                    _vm._s(_vm.$t("last_update_in", { date: _vm.lastUpdate }))
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "round-details-content" }, [
              _c("div", { staticClass: "round-details-content-header" }, [
                _c("div", [
                  _c(
                    "h1",
                    { staticClass: "round-details-content-header-title" },
                    [_vm._v(" " + _vm._s(_vm.$t("round_details_title")) + " ")]
                  ),
                  _c(
                    "p",
                    { staticClass: "round-details-content-header-sub-title" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("round_details_actions", {
                              status: _vm.roundDetailsStatus,
                              date: _vm.formatData(_vm.roundData.updatedAt),
                              user_name: _vm.roundData.updatedBy,
                            })
                          ) +
                          " "
                      ),
                    ]
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "round-details-content-button-edit" },
                  [
                    _c("NewDxaButton", {
                      attrs: {
                        "data-test": "Round:RoundDetails:ButtonGoEditRound",
                        title: _vm.$t("edit_round_information"),
                        disabled:
                          _vm.buttonEditRoundDisabled || _vm.isManagerUser,
                      },
                      on: { btnFunction: _vm.editRound },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "round-details-content-bg" }, [
                _c("ul", { staticClass: "round-details-content-list" }, [
                  _c("li", { staticClass: "round-details-content-list-item" }, [
                    _c(
                      "h2",
                      { staticClass: "round-details-content-items-label" },
                      [_vm._v(" " + _vm._s(_vm.$tc("company", 1)) + " ")]
                    ),
                    _c("div", { staticClass: "round-details-company-flex" }, [
                      _c(
                        "div",
                        { staticClass: "round-details-content-items-value" },
                        [_vm._v(" " + _vm._s(_vm.roundData.companyName) + " ")]
                      ),
                      !_vm.isManagerUser
                        ? _c(
                            "a",
                            {
                              staticClass: "round-details-see-company-details",
                              on: {
                                click: function ($event) {
                                  return _vm.redirectToCompanyDetails(
                                    _vm.roundData.companyId
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("see_company_details")) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ]),
                  _c("li", { staticClass: "round-details-content-list-item" }, [
                    _c(
                      "h2",
                      { staticClass: "round-details-content-items-label" },
                      [_vm._v(" " + _vm._s(_vm.$t("manager_company")) + " ")]
                    ),
                    _c("div", { staticClass: "round-details-company-flex" }, [
                      _c(
                        "div",
                        { staticClass: "round-details-content-items-value" },
                        [
                          _c(
                            "p",
                            {
                              staticClass: "round-details-content-items-value",
                              class: {
                                "round-details-content-items-value-not-found":
                                  !_vm.roundData.managerPartnerName,
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.roundData.managerPartnerName
                                      ? _vm.roundData.managerPartnerName
                                      : _vm.$t("not_defined")
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm.roundData.managerPartnerId && !_vm.isManagerUser
                        ? _c(
                            "a",
                            {
                              staticClass: "round-details-see-company-details",
                              on: { click: _vm.handleRedirectManagerCompany },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("see_manager_company_details")
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ]),
                  _c("li", { staticClass: "round-details-content-list-item" }, [
                    _c(
                      "h2",
                      { staticClass: "round-details-content-items-label" },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("round_investment_target")) + " "
                        ),
                      ]
                    ),
                    _c(
                      "p",
                      {
                        staticClass: "round-details-content-items-value",
                        class: {
                          "round-details-content-items-value-not-found":
                            !_vm.roundData.investmentTarget,
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.roundData.investmentTarget
                                ? _vm.gs.format_to_currency(
                                    _vm.roundData.investmentTarget
                                  )
                                : _vm.$t("not_defined")
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]),
                  _c("li", { staticClass: "round-details-content-list-item" }, [
                    _c(
                      "h2",
                      { staticClass: "round-details-content-items-label" },
                      [_vm._v(" " + _vm._s(_vm.$t("status")) + " ")]
                    ),
                    _c(
                      "p",
                      { staticClass: "round-details-content-items-value" },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.getStatusText(_vm.roundData.roundStatus)
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]),
                  _c("li", { staticClass: "round-details-content-list-item" }, [
                    _c(
                      "h2",
                      { staticClass: "round-details-content-items-label" },
                      [_vm._v(" " + _vm._s(_vm.$t("startDate")) + " ")]
                    ),
                    _c(
                      "p",
                      {
                        staticClass: "round-details-content-items-value",
                        class: {
                          "round-details-content-items-value-not-found":
                            !_vm.formatData(_vm.roundData.captureStartDate),
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.checkValueTextIsValid(
                                _vm.formatData(_vm.roundData.captureStartDate)
                              )
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]),
                  _c("li", { staticClass: "round-details-content-list-item" }, [
                    _c(
                      "h2",
                      { staticClass: "round-details-content-items-label" },
                      [_vm._v(" " + _vm._s(_vm.$t("endDate")) + " ")]
                    ),
                    _c(
                      "p",
                      {
                        staticClass: "round-details-content-items-value",
                        class: {
                          "round-details-content-items-value-not-found":
                            !_vm.formatData(_vm.roundData.captureFinishDate),
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.checkValueTextIsValid(
                                _vm.formatData(_vm.roundData.captureFinishDate)
                              )
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]),
                  _c("li", { staticClass: "round-details-content-list-item" }, [
                    _c(
                      "h2",
                      { staticClass: "round-details-content-items-label" },
                      [_vm._v(" " + _vm._s(_vm.$t("briefing")) + " ")]
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "round-details-content-link",
                        on: {
                          click: function () {
                            return _vm.handleGoBriefing()
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("see_briefing_information")))]
                    ),
                  ]),
                  _c("li", { staticClass: "round-details-content-list-item" }, [
                    _c(
                      "h2",
                      { staticClass: "round-details-content-items-label" },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("round_permission_opportinity")) +
                            " "
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "round-details-content-items-value",
                        class: {
                          "round-details-content-items-value-not-found":
                            !_vm.roundData.typeOpportunity,
                        },
                      },
                      [
                        _vm.roundData.typeOpportunity
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "round-details-content-type-opportunity-box",
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getTypeRound(
                                        _vm.roundData.typeOpportunity
                                      )
                                    ) + " "
                                  ),
                                ]),
                                _vm.roundData.typeOpportunity ===
                                _vm.roundTypeOpportunity.ExclusiveOpportunity
                                  ? _c("span", [
                                      !_vm.isManagerUser
                                        ? _c(
                                            "a",
                                            {
                                              staticClass:
                                                "round-details-content-link",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.$router.push(
                                                    "/round/permissions/" +
                                                      _vm.roundData.id
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("see_permissions")
                                                ) + " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ])
                                  : _vm._e(),
                              ]
                            )
                          : _c("span", [_vm._v(_vm._s(_vm.$t("not_defined")))]),
                      ]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "round-details-content-commitment" }, [
                  _c(
                    "h2",
                    { staticClass: "round-details-content-commitment-title" },
                    [_vm._v(" " + _vm._s(_vm.$t("commitment_documents")) + " ")]
                  ),
                  _c("ul", { staticClass: "round-details-content-list" }, [
                    _c(
                      "li",
                      { staticClass: "round-details-content-list-item" },
                      [
                        _c(
                          "h4",
                          {
                            staticClass:
                              "round-details-content-list-item-term-language",
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("term_title_language_pt")) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "h3",
                          { staticClass: "round-details-content-items-label" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("commitment_term_key", {
                                    language: "pt",
                                  })
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "p",
                          {
                            staticClass: "round-details-content-link",
                            class: {
                              "round-details-content-items-value-not-found":
                                JSON.parse(_vm.roundData.commitmentTermUrl)
                                  .pt === "",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.checkParseValueTextIsValid(
                                    _vm.roundData.commitmentTermUrl,
                                    "pt"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "li",
                      { staticClass: "round-details-content-list-item" },
                      [
                        _c(
                          "h3",
                          { staticClass: "round-details-content-items-label" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("proxy_key", { language: "pt" })
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "p",
                          {
                            staticClass: "round-details-content-link",
                            class: {
                              "round-details-content-items-value-not-found":
                                JSON.parse(_vm.roundData.investmentProxyUrl)
                                  .pt === "",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.checkParseValueTextIsValid(
                                    _vm.roundData.investmentProxyUrl,
                                    "pt"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "li",
                      { staticClass: "round-details-content-list-item" },
                      [
                        _c(
                          "h4",
                          {
                            staticClass:
                              "round-details-content-list-item-term-language",
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("term_title_language_en")) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "h3",
                          { staticClass: "round-details-content-items-label" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("commitment_term_key", {
                                    language: "en",
                                  })
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "p",
                          {
                            staticClass: "round-details-content-link",
                            class: {
                              "round-details-content-items-value-not-found":
                                JSON.parse(_vm.roundData.commitmentTermUrl)
                                  .en === "",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.checkParseValueTextIsValid(
                                    _vm.roundData.commitmentTermUrl,
                                    "en"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "li",
                      { staticClass: "round-details-content-list-item" },
                      [
                        _c(
                          "h3",
                          { staticClass: "round-details-content-items-label" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("proxy_key", { language: "en" })
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "p",
                          {
                            staticClass: "round-details-content-link",
                            class: {
                              "round-details-content-items-value-not-found":
                                JSON.parse(_vm.roundData.investmentProxyUrl)
                                  .en === "",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.checkParseValueTextIsValid(
                                    _vm.roundData.investmentProxyUrl,
                                    "en"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                  _c(
                    "h2",
                    {
                      staticClass:
                        "round-details-content-commitment-title round-details-content-partner-conditions-title",
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("partner_specials_conditions")) +
                          " "
                      ),
                    ]
                  ),
                  _vm.roundData.partnerConditionInvestmentRounds.length
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "round-details-content-commitment-partner",
                        },
                        _vm._l(
                          _vm.roundData.partnerConditionInvestmentRounds,
                          function (partner, i) {
                            return _c(
                              "ul",
                              {
                                key: i,
                                staticClass: "round-details-content-list",
                              },
                              [
                                _c(
                                  "li",
                                  {
                                    staticClass:
                                      "round-details-content-list-item",
                                  },
                                  [
                                    _c(
                                      "h3",
                                      {
                                        staticClass:
                                          "round-details-content-items-label",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$tc("partner", 1)) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "round-details-content-items-value",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(partner.partnerB2bName) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c("li", [
                                  _c(
                                    "h4",
                                    {
                                      staticClass:
                                        "round-details-content-list-item-term-language",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("term_title_language_pt")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]),
                                _c(
                                  "li",
                                  {
                                    staticClass:
                                      "round-details-content-list-item",
                                  },
                                  [
                                    _c(
                                      "h3",
                                      {
                                        staticClass:
                                          "round-details-content-items-label",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("commitment_term_key", {
                                                language: "pt",
                                              })
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "round-details-content-link",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              JSON.parse(
                                                partner.partnerCommitmentTermUrl
                                              ).pt
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "li",
                                  {
                                    staticClass:
                                      "round-details-content-list-item",
                                  },
                                  [
                                    _c(
                                      "h3",
                                      {
                                        staticClass:
                                          "round-details-content-items-label",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("proxy_key", {
                                                language: "pt",
                                              })
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "round-details-content-link",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              JSON.parse(
                                                partner.partnerInvestmentProxyUrl
                                              ).pt
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c("li", [
                                  _c(
                                    "h4",
                                    {
                                      staticClass:
                                        "round-details-content-list-item-term-language",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("term_title_language_en")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]),
                                _c(
                                  "li",
                                  {
                                    staticClass:
                                      "round-details-content-list-item",
                                  },
                                  [
                                    _c(
                                      "h3",
                                      {
                                        staticClass:
                                          "round-details-content-items-label",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("commitment_term_key", {
                                                language: "en",
                                              })
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "round-details-content-link",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              JSON.parse(
                                                partner.partnerCommitmentTermUrl
                                              ).en
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "li",
                                  {
                                    staticClass:
                                      "round-details-content-list-item",
                                  },
                                  [
                                    _c(
                                      "h3",
                                      {
                                        staticClass:
                                          "round-details-content-items-label",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("proxy_key", {
                                                language: "en",
                                              })
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "round-details-content-link",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              JSON.parse(
                                                partner.partnerInvestmentProxyUrl
                                              ).en
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          }
                        ),
                        0
                      )
                    : _c("div", [
                        _c(
                          "ul",
                          { staticClass: "round-details-content-list" },
                          [
                            _c(
                              "li",
                              {
                                staticClass: "round-details-content-list-item",
                              },
                              [
                                _c(
                                  "h3",
                                  {
                                    staticClass:
                                      "round-details-content-items-label",
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$tc("partner", 1)) + " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "round-details-content-items-value round-details-content-items-value-not-found",
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("not_defined")) + " "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _c("li", [
                              _c(
                                "h4",
                                {
                                  staticClass:
                                    "round-details-content-list-item-term-language",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("term_title_language_pt")) +
                                      " "
                                  ),
                                ]
                              ),
                            ]),
                            _c(
                              "li",
                              {
                                staticClass: "round-details-content-list-item",
                              },
                              [
                                _c(
                                  "h3",
                                  {
                                    staticClass:
                                      "round-details-content-items-label",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("commitment_term_key", {
                                            language: "pt",
                                          })
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "round-details-content-items-value round-details-content-items-value-not-found",
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("not_defined")) + " "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "li",
                              {
                                staticClass: "round-details-content-list-item",
                              },
                              [
                                _c(
                                  "h3",
                                  {
                                    staticClass:
                                      "round-details-content-items-label",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("proxy_key", {
                                            language: "pt",
                                          })
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "round-details-content-items-value round-details-content-items-value-not-found",
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("not_defined")) + " "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _c("li", [
                              _c(
                                "h4",
                                {
                                  staticClass:
                                    "round-details-content-list-item-term-language",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("term_title_language_en")) +
                                      " "
                                  ),
                                ]
                              ),
                            ]),
                            _c(
                              "li",
                              {
                                staticClass: "round-details-content-list-item",
                              },
                              [
                                _c(
                                  "h3",
                                  {
                                    staticClass:
                                      "round-details-content-items-label",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("commitment_term_key", {
                                            language: "en",
                                          })
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "round-details-content-items-value round-details-content-items-value-not-found",
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("not_defined")) + " "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "li",
                              {
                                staticClass: "round-details-content-list-item",
                              },
                              [
                                _c(
                                  "h3",
                                  {
                                    staticClass:
                                      "round-details-content-items-label",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("proxy_key", {
                                            language: "en",
                                          })
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "round-details-content-items-value round-details-content-items-value-not-found",
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("not_defined")) + " "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                ]),
                _c(
                  "div",
                  { staticClass: "round-details-content-closing" },
                  [
                    _c(
                      "h2",
                      { staticClass: "round-details-content-closing-title" },
                      [_vm._v(" " + _vm._s(_vm.$t("closing_documents")) + " ")]
                    ),
                    _vm._l(
                      _vm.saVehicles(
                        _vm.roundData.investmentVehicleInvestmentRounds
                      ),
                      function (vehicle, i) {
                        return _c(
                          "ul",
                          { key: i, staticClass: "round-details-content-list" },
                          [
                            _c(
                              "li",
                              {
                                staticClass: "round-details-content-list-item",
                              },
                              [
                                _c(
                                  "h3",
                                  {
                                    staticClass:
                                      "round-details-content-items-label",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          "" + _vm.$t("vehicle_title_single")
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "round-details-content-closing-vehicle",
                                  },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "round-details-content-items-value",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              vehicle.vehicleName +
                                                " - " +
                                                _vm.formatVehicleCnpj(
                                                  vehicle.vehicleCnpj
                                                )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    !_vm.isManagerUser
                                      ? _c(
                                          "a",
                                          {
                                            staticClass:
                                              "round-details-content-link",
                                            on: {
                                              click: function ($event) {
                                                return _vm.redirectToVehicleDetails(
                                                  vehicle.investmentVehicleId
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("see_vehicle_details")
                                              )
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "span",
                                      { staticClass: "vehicle-nationality" },
                                      [
                                        _c("font-awesome-icon", {
                                          staticClass:
                                            "icon-vehicle-nationality",
                                          attrs: {
                                            icon: "fa-solid fa-database",
                                          },
                                        }),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.getVehicleNationality(
                                                vehicle.vehicleNationality
                                              )
                                            )
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "li",
                              {
                                staticClass: "round-details-content-list-item",
                              },
                              [
                                _c(
                                  "h3",
                                  {
                                    staticClass:
                                      "round-details-content-items-label",
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("stock_type")) + " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "round-details-content-items-value",
                                    class: {
                                      "round-details-content-items-value-not-found":
                                        !vehicle.stockTypeId,
                                    },
                                  },
                                  [
                                    vehicle.stockTypeId
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              vehicle.stockTypeName +
                                                " - " +
                                                _vm.formatCurrency(
                                                  vehicle.currency,
                                                  vehicle.stockTypeValue,
                                                  2,
                                                  5
                                                )
                                            ) + " "
                                          ),
                                        ])
                                      : _c("span", [
                                          _vm._v(_vm._s(_vm.$t("not_defined"))),
                                        ]),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "li",
                              {
                                staticClass: "round-details-content-list-item",
                              },
                              [
                                _c(
                                  "h3",
                                  {
                                    staticClass:
                                      "round-details-content-items-label",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("round_closing_adhesion_term")
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "round-details-content-items-value",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$tc("clicksign_key", 2)) +
                                        " "
                                    ),
                                    _c(
                                      "span",
                                      {
                                        class: {
                                          "round-details-content-items-value-not-found":
                                            !vehicle.adhesionTermSignatureKey,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.checkValueTextIsValid(
                                              vehicle.adhesionTermSignatureKey
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "li",
                              {
                                staticClass: "round-details-content-list-item",
                              },
                              [
                                _c(
                                  "h3",
                                  {
                                    staticClass:
                                      "round-details-content-items-label",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("newsletter_subscription_2")
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "round-details-content-items-value",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$tc("clicksign_key", 2)) +
                                        " "
                                    ),
                                    _c(
                                      "span",
                                      {
                                        class: {
                                          "round-details-content-items-value-not-found":
                                            !vehicle.newsletterSubscriptionSignatureKey,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.checkValueTextIsValid(
                                              vehicle.newsletterSubscriptionSignatureKey
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        )
                      }
                    ),
                    _vm._l(
                      _vm.llcVehicles(
                        _vm.roundData.investmentVehicleInvestmentRounds
                      ),
                      function (vehicle, i) {
                        return _c(
                          "ul",
                          { key: i, staticClass: "round-details-content-list" },
                          [
                            _c(
                              "li",
                              {
                                staticClass: "round-details-content-list-item",
                              },
                              [
                                _c(
                                  "h3",
                                  {
                                    staticClass:
                                      "round-details-content-items-label",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          "" + _vm.$t("vehicle_title_single")
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "round-details-content-closing-vehicle",
                                  },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "round-details-content-items-value",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s("" + vehicle.vehicleName) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    !_vm.isManagerUser
                                      ? _c(
                                          "a",
                                          {
                                            staticClass:
                                              "round-details-content-link",
                                            on: {
                                              click: function ($event) {
                                                return _vm.redirectToVehicleDetails(
                                                  vehicle.investmentVehicleId
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("see_vehicle_details")
                                              )
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "span",
                                      { staticClass: "vehicle-nationality" },
                                      [
                                        _c("font-awesome-icon", {
                                          staticClass:
                                            "icon-vehicle-nationality",
                                          attrs: {
                                            icon: "fa-solid fa-database",
                                          },
                                        }),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.getVehicleNationality(
                                                vehicle.vehicleNationality
                                              )
                                            )
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "li",
                              {
                                staticClass: "round-details-content-list-item",
                              },
                              [
                                _c(
                                  "h3",
                                  {
                                    staticClass:
                                      "round-details-content-items-label",
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("stock_type")) + " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "round-details-content-items-value",
                                    class: {
                                      "round-details-content-items-value-not-found":
                                        !vehicle.stockTypeId,
                                    },
                                  },
                                  [
                                    vehicle.stockTypeId
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              vehicle.stockTypeName +
                                                " - " +
                                                _vm.formatCurrency(
                                                  vehicle.currency,
                                                  vehicle.stockTypeValue,
                                                  2,
                                                  5
                                                )
                                            ) + " "
                                          ),
                                        ])
                                      : _c("span", [
                                          _vm._v(_vm._s(_vm.$t("not_defined"))),
                                        ]),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "li",
                              {
                                staticClass: "round-details-content-list-item",
                              },
                              [
                                _c(
                                  "h3",
                                  {
                                    staticClass:
                                      "round-details-content-items-label",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "round_closing_subscription_agreement"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "round-details-content-items-value",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$tc("clicksign_key", 2)) +
                                        " "
                                    ),
                                    _c(
                                      "span",
                                      {
                                        class: {
                                          "round-details-content-items-value-not-found":
                                            !vehicle.subscriptionAgreementSignatureKey,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.checkValueTextIsValid(
                                              vehicle.subscriptionAgreementSignatureKey
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        )
                      }
                    ),
                    _vm._l(
                      _vm.offVehicles(
                        _vm.roundData.investmentVehicleInvestmentRounds
                      ),
                      function (vehicle, i) {
                        return _c(
                          "ul",
                          { key: i, staticClass: "round-details-content-list" },
                          [
                            _c(
                              "li",
                              {
                                staticClass: "round-details-content-list-item",
                              },
                              [
                                _c(
                                  "h3",
                                  {
                                    staticClass:
                                      "round-details-content-items-label",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          "" + _vm.$t("vehicle_title_single")
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "round-details-content-closing-vehicle",
                                  },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "round-details-content-items-value",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s("" + vehicle.vehicleName) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    !_vm.isManagerUser
                                      ? _c(
                                          "a",
                                          {
                                            staticClass:
                                              "round-details-content-link",
                                            on: {
                                              click: function ($event) {
                                                return _vm.redirectToVehicleDetails(
                                                  vehicle.investmentVehicleId
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("see_vehicle_details")
                                              )
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "span",
                                      { staticClass: "vehicle-nationality" },
                                      [
                                        _c("font-awesome-icon", {
                                          staticClass:
                                            "icon-vehicle-nationality",
                                          attrs: {
                                            icon: "fa-solid fa-database",
                                          },
                                        }),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.getVehicleNationality(
                                                vehicle.vehicleNationality
                                              )
                                            )
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "li",
                              {
                                staticClass: "round-details-content-list-item",
                              },
                              [
                                _c(
                                  "h3",
                                  {
                                    staticClass:
                                      "round-details-content-items-label",
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("stock_type")) + " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "round-details-content-items-value",
                                    class: {
                                      "round-details-content-items-value-not-found":
                                        !vehicle.stockTypeId,
                                    },
                                  },
                                  [
                                    vehicle.stockTypeId
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              vehicle.stockTypeName +
                                                " - " +
                                                _vm.formatCurrency(
                                                  vehicle.currency,
                                                  vehicle.stockTypeValue,
                                                  2,
                                                  5
                                                )
                                            ) + " "
                                          ),
                                        ])
                                      : _c("span", [
                                          _vm._v(_vm._s(_vm.$t("not_defined"))),
                                        ]),
                                  ]
                                ),
                              ]
                            ),
                            _c("li", [
                              _c(
                                "h3",
                                {
                                  staticClass:
                                    "round-details-content-items-label",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("no_formalization_documents")
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        )
                      }
                    ),
                  ],
                  2
                ),
                _c(
                  "div",
                  { staticClass: "round-details-content-button-edit-end-page" },
                  [
                    _c("NewDxaButton", {
                      attrs: {
                        "data-test": "Round:RoundDetails:ButtonGoEditRound",
                        title: _vm.$t("edit_round_information"),
                        disabled:
                          _vm.buttonEditRoundDisabled || _vm.isManagerUser,
                      },
                      on: { btnFunction: _vm.editRound },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
      _vm.closeOrOpenRoundDialog
        ? _c(
            VDialog,
            {
              attrs: { "max-width": "564" },
              model: {
                value: _vm.closeOrOpenRoundDialog,
                callback: function ($$v) {
                  _vm.closeOrOpenRoundDialog = $$v
                },
                expression: "closeOrOpenRoundDialog",
              },
            },
            [
              _c(
                "div",
                { staticClass: "close-or-open-round-dialog-bg" },
                [
                  _c(
                    "div",
                    { staticClass: "close-or-open-round-dialog-content" },
                    [
                      _c("font-awesome-icon", {
                        staticClass: "close-or-open-round-dialog-icon-alert",
                        attrs: { icon: "fa-solid fa-triangle-exclamation" },
                      }),
                      _c(
                        "h1",
                        { staticClass: "close-or-open-round-dialog-title" },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.closeOrOpenRoundDialogTitle) + " "
                          ),
                        ]
                      ),
                      _c(
                        "p",
                        { staticClass: "close-or-open-round-dialog-text" },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.closeOrOpenRoundDialogText) + " "
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "close-or-open-round-dialog-buttons" },
                        [
                          _c(
                            "div",
                            [
                              _c("NewDxaButton", {
                                attrs: {
                                  outline: true,
                                  title: _vm.$t("cancel"),
                                },
                                on: {
                                  btnFunction: function ($event) {
                                    _vm.closeOrOpenRoundDialog = false
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c("NewDxaButton", {
                                attrs: {
                                  loading: _vm.closeOrOpenRoundLoading,
                                  title: _vm.closeOrOpenRoundDialogButton,
                                },
                                on: { btnFunction: _vm.closeOrOpenRound },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("font-awesome-icon", {
                    staticClass: "close-or-open-round-dialog-icon-close",
                    attrs: { icon: "fa-solid fa-xmark" },
                    on: {
                      click: function ($event) {
                        _vm.closeOrOpenRoundDialog = false
                      },
                    },
                  }),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm.cancelRoundDialog
        ? _c(
            VDialog,
            {
              attrs: { "max-width": "464" },
              model: {
                value: _vm.cancelRoundDialog,
                callback: function ($$v) {
                  _vm.cancelRoundDialog = $$v
                },
                expression: "cancelRoundDialog",
              },
            },
            [
              _c(
                "div",
                { staticClass: "cancel-round-dialog-bg" },
                [
                  _c(
                    "div",
                    { staticClass: "cancel-round-dialog-content" },
                    [
                      _c("font-awesome-icon", {
                        staticClass: "cancel-round-dialog-icon-alert",
                        attrs: { icon: "fa-solid fa-triangle-exclamation" },
                      }),
                      _c("h1", { staticClass: "cancel-round-dialog-title" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("cancel_round_dialog_title")) +
                            " "
                        ),
                      ]),
                      _c("p", {
                        staticClass: "cancel-round-dialog-text",
                        domProps: {
                          innerHTML: _vm._s(_vm.dialogCancelRoundText),
                        },
                      }),
                      _c(
                        "div",
                        { staticClass: "cancel-round-dialog-buttons" },
                        [
                          _c(
                            "div",
                            [
                              _c("NewDxaButton", {
                                attrs: {
                                  outline: true,
                                  title: _vm.$t("close"),
                                },
                                on: {
                                  btnFunction: function ($event) {
                                    _vm.cancelRoundDialog = false
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c("NewDxaButton", {
                                attrs: {
                                  loading: _vm.cancelRoundDialogLoading,
                                  title: _vm.$t("cancel"),
                                },
                                on: { btnFunction: _vm.cancelRound },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("font-awesome-icon", {
                    staticClass: "cancel-round-dialog-icon-close",
                    attrs: { icon: "fa-solid fa-xmark" },
                    on: {
                      click: function ($event) {
                        _vm.cancelRoundDialog = false
                      },
                    },
                  }),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }